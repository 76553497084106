import { useLobbyGameApi } from '@/api/lobby-game'
import { useAppStore } from '@/store/app'

export const useJackpot = () => {
  const { $axios } = useNuxtApp()
  const { setJackpot, setSumJackpot } = useAppStore()
  const { JACKPOT, SUM_JACKPOT } = useLobbyGameApi()
  const jackpotInterval = ref(null)
  const sumJackpotInterval = ref(null)
  const JACKPOT_INTERVAL = useRuntimeConfig().JACKPOT_INTERVAL

  const fetchJackpot = async () => {
    try {
      const response = await $axios.$get(JACKPOT)
      if (response && response.status === 'OK') {
        setJackpot(response.data)
      }
    } catch (error) {
      console.log('error', error?.message)
    }
  }

  const fetchSumJackpot = async () => {
    try {
      const response = await $axios.$get(SUM_JACKPOT)
      if (response && response.status === 'OK') {
        setSumJackpot(response.data)
      }
    } catch (error) {
      console.log('error', error?.message)
    }
  }

  const onJackpotIntervalClear = () => {
    jackpotInterval.value && clearInterval(jackpotInterval.value)
  }

  const onJackpotInterval = () => {
    fetchJackpot()
    jackpotInterval.value = setInterval(fetchJackpot, JACKPOT_INTERVAL)
  }

  const onSumJackpotIntervalClear = () => {
    sumJackpotInterval.value && clearInterval(sumJackpotInterval.value)
  }

  const onSumJackpotInterval = () => {
    fetchSumJackpot()
    sumJackpotInterval.value = setInterval(fetchSumJackpot, JACKPOT_INTERVAL)
  }

  return {
    fetchJackpot,
    fetchSumJackpot,
    onJackpotInterval,
    onJackpotIntervalClear,
    onSumJackpotIntervalClear,
    onSumJackpotInterval
  }
}
